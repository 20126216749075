import React from 'react'
import { Link } from 'gatsby'
import Img from "gatsby-image"
import htmlDecode from "html-entities-decoder";


const TwoColumns = (props) => {
    const data = props;

    return (
        <div className="projects-area pt-70 pb-70">
            <div className="container">
                {data.lang === "EN"
                    ?
                    <div className="text-center dark-mode-texts mb-8 mb-lg-18" style={{ paddingBottom: "2rem" }}>
                        <h2 className="font-size-11">Effective Digital Strategy</h2>
                        <p className="font-size-7 mb-0 opacity-7" style={{ paddingBottom: "2rem" }}>
                            Integrated management, targeted content, measurable results.
                        </p>
                    </div>
                    : (
                        data.lang === "FR"
                            ?
                            <div className="text-center dark-mode-texts mb-8 mb-lg-18" style={{ paddingBottom: "2rem" }}>
                                <h2 className="font-size-11">Stratégie numérique efficace</h2>
                                <p className="font-size-7 mb-0 opacity-7" style={{ paddingBottom: "2rem" }}>
                                    Gestion intégrée, contenu ciblé, résultats mesurables
                                </p>
                            </div>
                            :
                            <div className="text-center dark-mode-texts mb-8 mb-lg-18" style={{ paddingBottom: "2rem" }}>
                                <h2 className="font-size-11">Αποτελεσματική Digital Στρατηγική</h2>
                                <p className="font-size-7 mb-0 opacity-7" style={{ paddingBottom: "2rem" }}>
                                    Ολοκληρωμένη διαχείριση, στοχευμένο περιεχόμενο, μετρήσιμα αποτελέσματα.
                                </p>
                            </div>
                    )


                }
                <div className="row " style={{ justifyContent: "center" }}>
                    {data.caseStudyProjects && data.caseStudyProjects.map((cs, i) => (
                        <div className="col-lg-4 col-md-4" key={`csp-row-${i}`}>
                            {cs.node.caseStudyAcf.isnested
                                ?
                                <Link to={cs.node.uri} >
                                    <div className="single-projects-box">
                                        <div className="image">
                                            <Img fluid={cs.node.caseStudyAcf.sectionImage.imageFile.childImageSharp.fluid} alt={cs.node.caseStudyAcf.sectionImage.altText} className="cs-trans" />
                                        </div>

                                        <div className="content">
                                            <h3>
                                                <Link to={cs.node.uri}>
                                                    {htmlDecode(cs.node.title)}
                                                </Link>
                                            </h3>
                                            <span>{cs.node.caseStudyAcf.tag}</span>
                                        </div>
                                    </div>
                                </Link>
                                :
                                <div>
                                    <div className="single-projects-box">
                                        <div className="image">
                                            <Img fluid={cs.node.caseStudyAcf.sectionImage.imageFile.childImageSharp.fluid} alt={cs.node.caseStudyAcf.sectionImage.altText} className="cs-trans" />
                                        </div>

                                        <div className="content">
                                            <h3>
                                                <div>
                                                    {htmlDecode(cs.node.title)}
                                                </div>
                                            </h3>
                                            <span>{cs.node.caseStudyAcf.tag}</span>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>

                    ))}
                </div>
            </div>
        </div>
    )
}

export default TwoColumns