import React, { useState, useEffect } from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from '../components/Common/PageBanner'
import Footer from "../components/App/Footer"
import TwoColumns from '../components/Studies/TwoColumns'
import TwoColumnsWeb from '../components/Studies/TwoColumnsWeb'
// import ReactQr from 'react-awesome-qr';
// import Myimg from '../../src/assets/images/case-study/atamian2.png'


const CaseStudiesTemplate = (props) => {
    let data = props.data.wpgraphql;

    const [caseStudiesActive, setCaseStudiesActive] = useState(data.caseStudiesCategories.edges[1].node.caseStudies.edges)

    const [activeTab, setActiveTab] = useState(1)

    console.log(data)

    function constructMetaData(page, currentPage, language) {
        let payload = {
            title: page.seo.title,
            description: page.seo.metaDesc,
            canonical: `https://w3vitals.com${currentPage}`,
            fbTitle: page.seo.opengraphTitle ? page.seo.opengraphTitle : page.seo.title,
            fbDescription: page.seo.opengraphDescription ? page.seo.opengraphDescription : page.seo.metaDesc,
            fbUrl: `https://w3vitals.com${currentPage}`,
            fbType: "website",
            locale: language.toLowerCase(),
            image: page.seo.opengraphImage ? page.seo.opengraphImage.sourceUrl : ""
        }
        return payload
    }

    function changeTab(num) {
        setCaseStudiesActive(data.caseStudiesCategories.edges[num].node.caseStudies.edges)
        console.log(num)
        setActiveTab(num)
    }

    useEffect(() => {
        const queryString = window.location.search;
        const parameters = new URLSearchParams(queryString);
        const value = parameters.get('tab');
        if (parseInt(value) === 1) {
            setCaseStudiesActive(data.caseStudiesCategories.edges[1].node.caseStudies.edges)
            setActiveTab(1)
        }
    }, [])

    return (
        <Layout metaData={constructMetaData(data.page, props.pageContext.currentPage, props.pageContext.language)}>
            <Navbar header={props.pageContext.headerMenu}  lang={props.pageContext.language} />
            <PageBanner
                pageTitle={data.page.caseStudiesPageAcf.introductionHeader}
                homePageText={props.pageContext.language ==="EN" ? "Home" : (props.pageContext.language ==="FR" ? "Accueil" : "Αρχική")}
                homePageUrl={props.pageContext.language ==="EN" ? "/en/" : (props.pageContext.language ==="FR" ? "/fr/" : "/")}
                activePageText={data.page.caseStudiesPageAcf.introductionHeader}
            />

            {/* <h2 className="pt-100 text-center">Curious what we did with others? See some of our (not under NDA) works.</h2> */}
            <div className="d-flex justify-center justify-content-center pt-4" style={{ paddingBottom: "3rem" }}>
                {/* <button onClick={() => changeTab(0)} className={`pill-btn ${activeTab === 0 ? 'pill-btn-active' : ''}`} >Digital</button> */}
                <button onClick={() => changeTab(1)} className={`pill-btn-left ${activeTab === 1 ? 'pill-btn-active' : ''}`} >Web</button>

                <button onClick={() => changeTab(0)} className={`pill-btn-right ${activeTab === 0 ? 'pill-btn-active' : ''}`} >Digital</button>
            </div>
            {activeTab === 1
                ?
                <TwoColumnsWeb
                    lang={props.pageContext.language}
                    caseStudyProjects={caseStudiesActive}
                />
                :
                <TwoColumns
                    lang={props.pageContext.language}
                    caseStudyProjects={caseStudiesActive}
                />
            }

            <Footer footer={props.pageContext.footerMenu} extra={data.footerTemplate.footerTemplateAcf} />
        </Layout>
    );
}

export default CaseStudiesTemplate

export const pageQuery = graphql`
    query GET_CASE_STUDIES($id: ID! , $language: WPGraphQL_LanguageCodeFilterEnum, $footerTemplateUri: ID! ) {
        wpgraphql {
            page(id: $id) {
                seo {
                    canonical
                    title
                    metaDesc
                    opengraphDescription
                    opengraphTitle
                    opengraphImage {
                        sourceUrl
                    }
                }
                breadcrumbAcf{
                    breadcrumbName
                }
			    caseStudiesPageAcf{
                    introductionHeader
                }
                content
            }
            caseStudies(first: 100,where: {language: $language}) {
                ...CaseStudiesFragment
            }
            caseStudiesCategories(where: {language: $language}){
                edges{
                  node{
                    name
                    caseStudies(first: 100){
                        edges {
                            node {
                                title
                                uri
                                caseStudyAcf {
                                    isnested
                                    tag
                                    featuredImage {
                                        sourceUrl
                                        imageFile{
                                            childImageSharp{
                                                fluid{
                                                    base64
                                                    aspectRatio
                                                    src
                                                    srcSet
                                                    srcWebp
                                                    srcSetWebp
                                                    sizes
                                                }
                                            }
                                        }
                                    }
                                    sectionImage {
                                        sourceUrl
                                        imageFile{
                                            childImageSharp{
                                                fluid{
                                                    base64
                                                    aspectRatio
                                                    src
                                                    srcSet
                                                    srcWebp
                                                    srcSetWebp
                                                    sizes
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                  }
                }
              }
            footerTemplate: template(id: $footerTemplateUri, idType: SLUG) {
                footerTemplateAcf{
                    addressTitle
                    addressContent
                    content
                }
            }
        }
    }
`